import React, { useState } from "react"
import { graphql, Link as GatsbyLink, navigate } from "gatsby"
import { Box, Checkbox, Heading, Input, Label, Text } from "theme-ui"
// import {chunk} from 'lodash'
import excerptHtml from "excerpt-html"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField"

// import app components
import Layout from "../../components/Layout"
import Edges from "../../components/Edges"
import textimage from "../../components/textImage/TextImage"
import BackgroundImage from "../../components/BackgroundImage"
import Button from "../../components/Button"
import Textarea from "../../components/Textarea"

import Pagination from "../../components/Pagination"

import useFilteredProducts from "../../hooks/useFilteredProducts"

const blocks = {
  textimage,
}
const Template = (props) => {
  const {
    data: {
      wpPage: {
        seo,
        uri: basePath,
        template: { acf },
      },

      allWpProductCat: { nodes: categories },
      allWpProductTag: { nodes: tags },
    },

    pageContext: { postsPerPage, totalPages: numberOfPages, currentPage: page },
  } = props

  const [categoryFilters, setCategoryFilters] = useState({})

  const handleCategoryChange = (e) => {
    const { name, checked } = e.target || {}
    setCategoryFilters({ ...categoryFilters, [name]: checked })
  }

  const [search, setSearch] = useState("")
  const products = useFilteredProducts({ categoryFilters, search })

  const handleSearchChange = (event, value) => {
    setSearch(value[0])
  }

  const newTags = tags.map((tag) => tag.name.toLowerCase())

  return (
    <Layout {...props} seo={seo}>
      {acf?.content?.flex &&
        acf.content.flex.map(
          ({ id: fieldId, fieldGroupName, ...rest }, index) => {
            const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

            const Component = blocks?.[id]
            return (
              Component && (
                <Box key={index} my={index > 0 ? [3, 5] : 0}>
                  <Component {...rest} />
                </Box>
              )
            )
          }
        )}
      <Box sx={{ backgroundColor: "almondLight" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0,
            bg: "bgWhite",
            pb: ["50px", "100px"],
          }}
        >
          <Edges size="md">
            <Box sx={{ my: "40px" }}>
              <Autocomplete
                multiple
                options={newTags}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                onChange={handleSearchChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search"
                    placeholder="Enter title or select tag..."
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
                my: 5,
                // "@media (max-width:1110px)": {
                //   maxWidth: "450px",
                //   justifyContent: "center",
                //   mx: "auto",
                // },
              }}
            >
              {/* <Box
                variant="text.productsButton"
                sx={{
                  borderRadius: "5px",
                  gridColumnStart: 1,
                  gridRowStart: 1,
                  bg: "primary",
                  height: "100%",
                  width: "19%",
                  minWidth: "150px",
                  display: "flex",
                  pl: 3,
                  py: 3,
                  ml: "10px",
                  color: "white",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  svg: {
                    width: "20px",
                    height: "20px",
                    my: "auto",
                    ml: "auto",
                    mr: "5px",
                    display: "block",
                  },
                }}
              >
                {"All"}
                <Logo />
              </Box> */}
              {/* <Input onChange={handleSearchChange} /> */}

              {/* {console.log(categories)} */}

              {categories &&
                categories.map((category, index) => {
                  return (
                    <Label
                      key={category.databaseId}
                      sx={{
                        cursor: "pointer",
                        width: "fit-content",
                        minWidth: "150px",
                        mb: "20px",
                      }}
                    >
                      <Checkbox
                        id={category.name}
                        name={category.name}
                        type="checkbox"
                        checked={categoryFilters?.[category.name] || false}
                        onChange={handleCategoryChange}
                        defaultChecked={false}
                        variant="text.productsButton"
                      />
                      {/* {category.name} */}
                      <Text
                        className="text"
                        variant="text.productsButton"
                        sx={{ my: "auto", textAlign: "center" }}
                        children={category.name}
                      />
                    </Label>

                    // <SubMenuLink key={o.databaseId} to={o.uri}>
                    //   <Text
                    //     className="text"
                    //     variant="text.productsButton"
                    //     sx={{ my: "auto", textAlign: "center", height: "100%" }}
                    //     children={o.name.toUpperCase()}
                    //   />
                    // </SubMenuLink>
                  )
                })}
            </Box>
          </Edges>

          <Edges size="md">
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: ["1fr", "1fr 1fr"],
                "@media (min-width: 1000px)": {
                  gridTemplateColumns: "1fr 1fr 1fr",
                },
                gridTemplateRows: "auto",
                gap: "30px 10px",
                width: "auto",
                mb: 4,
              }}
            >
              {products.length > 0 ? (
                products.map((o) => (
                  <Box
                    sx={{
                      background: "white",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      position: "relative",
                      boxShadow: "0px 3px 6px #00000029",
                      transition: "0.3s",
                      "&:hover": {
                        boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                      },
                    }}
                    key={o.id}
                    onClick={() => navigate(o.uri)}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: 200,
                        width: "100%",
                      }}
                    >
                      {o.acf?.section1?.image && (
                        <BackgroundImage
                          image={o.acf?.section1?.image}
                          alt={o?.acf?.section1?.image?.altText}
                        />
                      )}
                    </Box>
                    <Edges>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          position: "relative",
                          py: 4,
                          gap: 3,
                        }}
                      >
                        {o.title && (
                          <GatsbyLink to={o.uri}>
                            <Heading
                              variant="styles.productsHeading"
                              sx={{ pb: 3 }}
                            >
                              {o.title}
                            </Heading>
                          </GatsbyLink>
                        )}

                        {o.acf?.section1?.text && (
                          <Box
                            sx={{
                              pb: 2,
                            }}
                          >
                            <Textarea
                              content={excerptHtml(o.acf.section1.text, {
                                pruneLength: 120,
                              })}
                            />
                          </Box>
                        )}
                        {
                          <Button customeVariant="buttons.products" to={o.uri}>
                            More
                          </Button>
                        }
                      </Box>
                    </Edges>
                  </Box>
                ))
              ) : (
                <>
                  <Heading
                    variant="styles.h5"
                    sx={{ fontSize: "6", mt: 18, ml: 18 }}
                  >
                    No Results
                  </Heading>
                  <Text
                    variant="text.introduction"
                    sx={{
                      display: "block",
                      letterSpacing: "-0.23px",
                      lineHeight: "25px",
                      mt: 18,
                      ml: 18,
                      mb: 18,
                    }}
                  >
                    Please try a different query
                  </Text>
                </>
              )}
            </Box>
          </Edges>

          {/* <Pagination
            numberOfPages={numberOfPages}
            page={page}
            basePath={basePath}
          
          /> */}
        </Box>
      </Box>
    </Layout>
  )
}

export const Query = graphql`
  query ProductArchive($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      template {
        templateName
        ... on WpDefaultTemplate {
          acf {
            content {
              flex {
                __typename
                ... on WpDefaultTemplate_Acf_Content_Flex_Textimage {
                  fieldGroupName
                  sections {
                    alignimage
                    backgroundcolor
                    fieldGroupName
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    image2 {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    link {
                      target
                      title
                      url
                    }
                    text
                  }
                }
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
    }
    allWpProductTag {
      nodes {
        name
      }
    }

    allWpProductCat {
      nodes {
        name
        databaseId
        uri
      }
    }
  }
`

export default Template
