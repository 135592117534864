import { useState, useEffect } from "react"
import useAllProducts from "./useAllProducts"

import { matchSorter } from "match-sorter"

const useFilteredProducts = ({ categoryFilters, search }) => {
  let products = useAllProducts()
  // console.log(products)
  // console.log(search)

  let searchedProducts = []

  const [filteredProducts, setFilteredProducts] = useState(products)

  useEffect(() => {
    /**
     * Product category filter
     */

    const activeCategories = Object.keys(categoryFilters).filter(
      (key) => !!categoryFilters[key]
    )

    if (activeCategories.length > 0) {
      let data = products.filter((product) => {
        let categorySelected = false

        const productCategories = product.productCat.nodes.map(
          (category) => category.name
        )

        activeCategories.forEach((activeCategory) => {
          if (productCategories.includes(activeCategory)) {
            categorySelected = true
          }
        })

        return !!categorySelected
      })

      if (search) {
        data = matchSorter(data, search, {
          keys: ["productTag.nodes.*.name", "title", "acf.section1.text"],
        })
        // console.log("test", searchedProducts)
        // setFilteredProducts(searchedProducts)
      }
      setFilteredProducts(data)
    } else if (activeCategories.length <= 0 && search) {
      let data = matchSorter(products, search, {
        keys: ["productTag.nodes.*.name", "title", "acf.section1.text"],
      })

      setFilteredProducts(data)
    } else {
      setFilteredProducts(products)
    }
  }, [categoryFilters, search])

  return filteredProducts
}
export default useFilteredProducts
