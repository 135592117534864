import { useStaticQuery, graphql } from "gatsby"

const useAllProducts = () => {
  const {
    allWpProduct: { nodes },
  } = useStaticQuery(graphql`
    {
      allWpProduct(sort: { fields: [date], order: DESC }) {
        nodes {
          id
          title
          uri
          date
          terms {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, placeholder: BLURRED)
                }
              }
            }
          }
          acf {
            fieldGroupName
            section1 {
              fieldGroupName
              text
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
              }
            }
          }
          productCat {
            nodes {
              name
            }
          }
          productCat {
            nodes {
              name
              databaseId
              uri
            }
          }
        }
      }
    }
  `)

  return nodes
}

export default useAllProducts
